import React from 'react';
import styled from 'styled-components';
import { Title2, Title3 } from '../home/homeStyledComponents';
import { colors, mobileThresholdPixels, ImgCover } from '../styledComponents';
import axaLogo from '../../assets/axa.png';
import Declare from './Declare';
import Assurance1 from '../../assets/assurance-1.jpg';
import Assurance3 from '../../assets/assurance-3.jpg';
import Assurance4 from '../../assets/assurance-4.jpg';
import InsuranceTable from './InsuranceTable';

const Container = styled.div`
  margin: 0px 100px;
  padding: 0px 0px 60px;
  color: ${colors.navy};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 0px;
  }
`;

const Logo = styled.img`
  width: 150px;
`;

const P = styled.p`
  @media (max-width: ${mobileThresholdPixels}) {
    margin-left: 5px;
    margin-right: 5px;
    text-align: justify;
  }
`;

const Exclusion = styled.ul`
  font-size: 13px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 10px;
  }
`;

const InsuranceBlock = () => (
  <Container>
    <ImgCover src={Assurance1} alt="Retouche express Paris" />
    <Title2 noMargin>L’assurance Tilli en partenariat avec AXA</Title2>
    <Title3 margin>Confiez en toute sérénité vos vêtements à nos Tillistes</Title3>
    <Logo src={axaLogo} alt="partenaire retouche" />

    <Title3 margin>Une assurance incluse à chaque commande</Title3>
    <P>Un incendie ou un dégât des eaux survient chez votre Tilliste pendant la mission.</P>
    <P>Un cambrioleur dérobe les vêtements que vous nous aviez confiés.</P>

    <Title3 margin>Que garantit l‘assurance AXA ?</Title3>
    <P>La garantie couvre tous les vêtements confiés à nos couturiers pendant la durée de la commande.</P>
    <P>
      Cette couverture vous est offerte par Tilli et vaut pour l’ensemble des commandes réalisées via la
      plateforme.
    </P>

    <ImgCover src={Assurance3} alt="Cintrage de robe" />

    <Title3 margin>Tableau des montant des garanties et des franchises</Title3>
    <InsuranceTable />

    <Title3 margin>Quelles sont les exclusions ?</Title3>
    <Exclusion>
      <li>
        <P>
          Les dommages subis par les biens que l’assuré détient en vertu d’un contrat de dépôt rémunéré ou qui
          lui sont remis en vue de la vente ou de la location ;
        </P>
      </li>
      <li>
        <P>
          Les dommages subis par les biens loués ou prêtés à titre onéreux à l’assuré ou qu’il détient en
          vertu d’un contrat de crédit-bail ou de location- vente ;
        </P>
      </li>
      <li>
        <P>
          Les dommages causés en cours de transport. Toutefois, si l’assuré n’est pas un transporteur
          professionnel, la garantie lui est acquise lorsqu’il effectue lui-même un transport accessoirement
          aux activités définies au contrat ;
        </P>
      </li>
      <li>
        <P>
          Les dommages subis par les espèces, les biens et objets de valeurs tels que titres, bijoux, pierreries,
          perles fines, objets en métaux précieux, pierres dures, statues, tableaux, collections, objets
          relevant du marché de l’art, fourrures ;
        </P>
      </li>
      <li>
        <P>
          Les objets essentiellement fragiles tels que verreries, porcelaines, terres cuites, plâtres,
          statues, céramiques, faïences, miroirs ;
        </P>
      </li>
      <li><P>Les objets en mauvais état au moment du sinistre ; </P></li>
      <li>
        <P>
          Le vol, la perte ou la disparition totale ou partielle des biens confiés. Toutefois,
          demeure garanti le vol de biens mobiliers  dans l’enceinte des établissements objets du contrat
          de prestation  causé par :
          <ul>
            <li><P>Les préposés de l’assuré au cours ou à l’occasion de leurs fonctions,</P></li>
            <li>
              <P>
                des tiers lorsque la responsabilité de l’assuré est engagée par suite d’une négligence imputable
                à lui-même ou à ses préposés.
              </P>
            </li>
          </ul>
        </P>
      </li>

      <P>ainsi que les dommages immatériels qui en sont la conséquence.</P>
    </Exclusion>

    <ImgCover src={Assurance4} alt="Retouche chemise haut t-shirt robe jupe" />

    <Declare />
  </Container>
);

export default InsuranceBlock;
